<template>
    <div>
        <card-header title="Reports" icon="fa-clipboard-list-check"/>

        <card-list>
            <a :href="`${apiUrl}api/reports/users?auth=${currentUser.token}`" target="_blank">
                <navigation-list-item title="Customers" icon="fa-users" class="t-card-list-item"/>
            </a>
            <a :href="`${apiUrl}api/reports/coaches?auth=${currentUser.token}`" target="_blank">
                <navigation-list-item title="Coaches" icon="fa-tennis-ball" class="t-card-list-item"/>
            </a>
        </card-list>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import {mapGetters} from "vuex";
    import NavigationListItem from "../../TIER/components/NavigationListItem.vue";
    import {client as http, apiUrl} from '@/http_client';

    export default {
        name: "Communication",
        props: ['card'],
        components: {NavigationListItem, CardList, CardHeader},
        data: function() {
            return {
                apiUrl
            }
        },
        computed: {
            ...mapGetters(['currentUser'])
        },
    };
</script>

<style lang="scss" scoped>

</style>
